<template>
  <div class="flex h-full flex-col border bg-white">
    <component
      :is="component"
      v-if="item.image"
      class="aspect-h-9 aspect-w-16"
      :to="item.image.context"
    >
      <CloudinaryImg
        :public-id="item.image.public_id"
        :steps="[200, 400, 600]"
        class="w-full border-b object-cover"
        :class="{ 'cursor-pointer': item.image.context }"
      />
    </component>
    <div class="grow p-2 text-left text-g7-blue lg:p-5">
      <Headline class="font-bold" size="2XL" :title="item.title" />
      <div
        v-if="item.subtitle"
        class="text-sm lg:text-base"
        v-html="item.subtitle"
      />
      <p
        v-if="item.text"
        :id="textId"
        :class="classes"
        class="text-xs sm:text-sm lg:text-base"
        v-html="item.text"
      />
    </div>
    <G7Button
      v-if="item.action_button && item.action_button.context"
      class="py-1"
      :label="item.action_button.label"
      :to="item.action_button.context"
    />
    <G7Button
      v-if="showMoreButton"
      class="py-1"
      :label="!showText ? item.action_button.label : 'Weniger Anzeigen'"
      @click="showText = !showText"
    />
  </div>
</template>

<script setup lang="ts">
import type { CruiseTeaser } from "~~/types/cruise";
const props = defineProps<{
  id?: number;
  item: CruiseTeaser;
}>();

const classes = ref("");

const textId = ref(`infocard_${props.id}`);

const lineHeight = computed(() => {
  if (window.innerWidth <= 640) {
    return 16;
  }
  if (window.innerWidth <= 1024) {
    return 20;
  }
  return 24;
});

function renderText() {
  if (showMoreButton.value) {
    const textElement = document.getElementById(textId.value);
    const result = textElement?.offsetHeight / lineHeight.value;
    if (result <= 5) {
      showMoreButton.value = false;
    }
    if (result > 5) {
      classes.value = "line-clamp-5";
    }
  }
}

onMounted(() => {
  if (process.client) {
    renderText();
  }
});

const component = computed(() => {
  if (props.item.image.context) {
    return resolveComponent("NuxtLink");
  }
  return "div";
});

const showText: Ref<boolean> = ref(false);
const showMoreButton: Ref<boolean> = ref(
  props.item.action_button &&
    !props.item.action_button.context &&
    props.item.text
);

watch(showText, () => {
  if (showText.value) {
    classes.value = "";
  }
  if (!showText.value) {
    classes.value = "line-clamp-5";
  }
});
</script>
